import icons from './icons';
import theme from './theme';
import currencies from './currencies';

const configs = {
  // product display information
  product: {
    name: 'Price Book',
  },

  // icon libraries
  icons,

  // theme configs
  theme,

  // currencies configs
  currencies,
};

export default configs;
