<template>
  <div class="text-center">
    <VImg contain max-height="400" src="/images/illustrations/500-illustration.svg" />
    <p class="mt-10">{{ $t('error.unexpected') }}</p>
    <p>
      {{ $t('error.help') }}
      <a href="tel:08006000">0800 6000</a>
    </p>
  </div>
</template>
