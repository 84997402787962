export const CompanyMessages = {
  administrative: {
    title: 'Facturatiegegevens',
    bic: 'BIC',
    email: 'Factuur e-mailadres',
    iban: 'IBAN',
    vatNumber: 'BTW nummer',
  },
  coc: {
    brancheNumber: 'Vestigingsnummer',
    number: 'KVK nummer',
    title: 'KVK',
  },
  name: 'Bedrijfsnaam',
  your: 'Jouw bedrijf',
};
