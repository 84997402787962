import { RouteRecordRaw } from 'vue-router';

export const showQuoteRoute = 'quote.show';

export const quoteRoutes: Readonly<RouteRecordRaw[]> = [
  {
    path: '/quote/:id',
    name: showQuoteRoute,
    component: () => import('../views/quote/QuoteOrderPage.vue'),
  },
];
