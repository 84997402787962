import errorRoutes from './error.routes';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { quoteRoutes } from './quote.routes.ts';
import { deviceQrRoutes } from './device-qr.routes.ts';

export const routes: Readonly<RouteRecordRaw[]> = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomePage.vue'),
  },

  ...deviceQrRoutes,
  ...quoteRoutes,

  // Must be at the end of the list, as all routes not defined before this will be seen as "not-found".
  ...errorRoutes,
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  // @ts-ignore
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    return { top: 0, left: 0 };
  },
});

/**
 * Before each route update
 */
// @ts-ignore
router.beforeEach((to, from, next) => {
  return next();
});

/**
 * After each route update
 */
router.afterEach(() => {});

export default router;
