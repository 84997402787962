export const QuoteMessages = {
  accepted: {
    error: 'De offerte is al geaccepteerd. Je kunt de offerte niet nogmaals accepteren.',
    title: 'Offerte is geaccepteerd',
    text: 'Jouw offerte is geaccepteerd en wordt verwerkt. Heb je vragen? Neem dan contact met ons op door te bellen naar 0800 6000.',
  },
  campaign: 'Campagne',
  header: {
    text: 'Hieronder vindt je de offerte voor jouw nieuwe Fore Freedom internetverbinding. Controleer de gegevens, upgrade eventueel het pakket en accepteer de offerte.',
    title: 'Offerte {name}',
  },
  noPackages: {
    text: 'Neem a.u.b. contact met ons op door te bellen naar 0800 6000. Dan lossen wij dit probleem op.',
    title: 'Geen aanbod gevonden op jouw adres',
  },
  success: {
    title: 'Jouw offerte is geaccepteerd en wordt verwerkt met ordernummer {number}',
    text: 'Wij gaan voor je aan de slag. Je ontvangt binnen enkele minuten een bevestiging per e-mail. Heb je vragen? Neem dan contact met ons op door te bellen naar 0800 6000.',
  },
};
