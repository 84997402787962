import nl from '../messages/nl';
import { createI18n } from 'vue-i18n';

export default createI18n<false>({
  legacy: false,
  locale: 'nl',
  messages: {
    nl,
  },
});
