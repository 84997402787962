import './bootstrap';
import { createPinia } from 'pinia';
import { createApp } from 'vue';
import App from './App.vue';

// VUE-ROUTER - https://router.vuejs.org/
import router from './router/index';

// Plugins
import i18n from './plugins/vue-i18n';
import vuetify from './plugins/vuetify.ts';
import './plugins/leaflet';
import './plugins/vee-validate.ts';

// STYLES
// Main Theme SCSS
import '../sass/theme.scss';

// App SCSS
import '../sass/app.scss';

/*
|---------------------------------------------------------------------
| Main Vue Instance
|---------------------------------------------------------------------
|
| Render the vue application on the <div id="app"></div> in index.html
*/
const app = createApp(App);

app.use(i18n);
app.use(createPinia());
app.use(router);
app.use(vuetify);

app.mount('#app');

export default app;
