<script setup lang="ts">
import DefaultLayout from './components/layouts/DefaultLayout.vue';
import ErrorLayout from './components/layouts/ErrorLayout.vue';
import { useToastStore } from './stores/toast.ts';
import { useRouter } from 'vue-router';
import { computed } from 'vue';

const router = useRouter();
const toast = useToastStore();

const isRouterLoaded = computed(() => router.currentRoute.value.name !== null);

const currentLayout = computed(() => {
  const layout = router.currentRoute.value.meta['layout'];

  return layout === 'error' ? ErrorLayout : DefaultLayout;
});
</script>

<template>
  <VApp>
    <!-- Layout component -->
    <component :is="currentLayout" v-if="isRouterLoaded">
      <RouterView v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <component :is="Component" />
        </transition>
      </RouterView>
    </component>

    <VSnackbar
      v-model="toast.show"
      :color="toast.color"
      :text="toast.message"
      :timeout="4000"
      location="top"
    />
  </VApp>
</template>
