import { defineStore } from 'pinia';
import configs from '../configs';

const { product, theme, currencies } = configs;
const { globalTheme, isContentBoxed } = theme;
const { currency, availableCurrencies } = currencies;

export const useAppStore = defineStore('app', {
  state: () => ({
    product,

    // currency
    currency,
    availableCurrencies,

    // themes and layout configurations
    globalTheme,
    isContentBoxed,
  }),
});
