export const DeviceQrMessages = {
  device: {
    details: 'Naam',
    macAddress: 'MAC adres',
    name: 'Apparaat naam',
    serialNumber: 'Serienummer',
  },
  success: {
    title: 'Jouw bestelling is succesvol geplaatst met ordernummer {number}!',
    text:
      'Wij gaan nu voor je aan de slag. Binnen enkele minuten ontvang je een bevestiging per e-mail en we zullen zo spoedig mogelijk contact met je opnemen. ' +
      'Heb je nog vragen? Neem dan contact met ons op door te bellen naar 0800 6000.',
  },
  welcome: {
    alert:
      'Dit het apparaat waarop je de QR-code hebt gescand en het bijbehorende adres waarop je de glasvezelverbinding wilt activeren. Klopt dit apparaat en/of adres niet? Neem dan contact op met onze klantenservice door te bellen naar 0800 6000.',
    title: 'Welkom in jouw nieuwe bedrijfspand!',
    text: 'Hier kunt je jouw glasvezelverbinding van Fore Freedom activeren. Nadat we de nodige gegevens bij de code hebben opgehaald, kun je de stappen doorlopen om jouw verbinding te bestellen. Wij gaan dan meteen voor je aan de slag.',
  },
};
