export type Currency = {
    label: string;
    decimalDigits: number;
    decimalSeparator: string;
    thousandsSeparator: string;
    currencySymbol: string;
    currencySymbolNumberOfSpaces: number;
    currencySymbolPosition: string;
}

const euro: Currency = {
    label: 'EUR',
    decimalDigits: 2,
    decimalSeparator: ',',
    thousandsSeparator: '.',
    currencySymbol: '€',
    currencySymbolNumberOfSpaces: 1,
    currencySymbolPosition: 'left'
};

export default {
  // current currency
  currency: euro,

  // available currencies for user selection
  availableCurrencies: [
      euro,
  ]
}
