<template>
  <div class="text-center w-full pa-3">
    <component :is="errorComponent" />
    <VBtn :block="true" to="/" large color="primary">{{ $t('error.sendBack') }}</VBtn>
  </div>
</template>

<script setup lang="ts">
import NotFoundCard from '../snippets/error/NotFoundCard.vue';
import UnexpectedCard from '../snippets/error/UnexpectedCard.vue';
import { computed } from 'vue';
import { isAxiosError } from 'axios';

const { error } = defineProps({ error: Error });

const errorComponent = computed(() => {
  if (isAxiosError(error) && error.status === 404) {
    return NotFoundCard;
  } else {
    return UnexpectedCard;
  }
});
</script>
