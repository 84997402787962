import { ThemeDefinition } from 'vuetify';

const dark: ThemeDefinition = {
  dark: true,
  colors: {
    background: '#111b27',
    surface: '#05090c',
    primary: '#ffdd00',
    secondary: '#829099',
    accent: '#82B1FF',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107',
  },
};

const light: ThemeDefinition = {
  dark: false,
  colors: {
    background: '#f5f5f5',
    surface: '#ffffff',
    primary: '#ffdd00',
    secondary: '#a0b9c8',
    accent: '#048ba8',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#ffd166',
  },
};

export default {
  // global theme for the app
  globalTheme: 'light', // light | dark

  // wrap pages content with a max-width
  isContentBoxed: true,

  // dark theme colors
  dark,

  // light theme colors
  light,
};
