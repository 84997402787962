import { configure, defineRule } from 'vee-validate';
import { localize, setLocale } from '@vee-validate/i18n';
import {
  email,
  integer,
  max,
  max_value,
  min,
  min_value,
  numeric,
  one_of,
  regex,
  required,
} from '@vee-validate/rules';
import nl from '@vee-validate/i18n/dist/locale/nl.json';

defineRule('accepted', (value: any) => ['yes', 'on', true, 1].includes(value));
defineRule(
  'bic',
  (value: any) => !value || value.length === 0 || /^[A-Z]{6}[A-Z0-9]{2}$/.test(value),
);
defineRule('email', email);
defineRule(
  'iban',
  (value: any) =>
    !value || value.length === 0 || /^[A-Z]{2}[0-9]{2}[A-Z]{4}[0-9]{10}$/.test(value),
);
defineRule('integer', integer);
defineRule('max', max);
defineRule('max_value', max_value);
defineRule('min', min);
defineRule('min_value', min_value);
defineRule('numeric', numeric);
defineRule('one_of', one_of);
defineRule('phone_number', (value: any) => /^[+]*[(]?[0-9]{1,4}[)]?[-\s/0-9]*$/.test(value));
defineRule('postal_code', (value: any) => /^[1-9][0-9]{3}[A-Z]{2}$/.test(value));
defineRule('regex', regex);
defineRule('required', required);
defineRule('required_if', (value: any, [target, ...conditions]: [string, any], ctx) => {
  if (conditions.some((condition) => ctx.form[target] === condition)) {
    return required(value);
  }

  return true;
});
defineRule('required_unless', (value, [target, ...conditions]: [string, any], ctx) => {
  if (conditions.every((condition) => ctx.form[target] !== condition)) {
    return required(value);
  }

  return true;
});
defineRule('required_with', (value, [target]: [string], ctx) => {
  if (ctx.form[target]) {
    return required(value);
  }

  return true;
});
defineRule('required_without', (value, [target]: [string], ctx) => {
  if (!ctx.form[target]) {
    return required(value);
  }

  return true;
});
defineRule(
  'vat_number',
  (value: any) => !value || value.length === 0 || /^NL[0-9]{9}B[0-9]{2}$/.test(value),
);

configure({
  generateMessage: localize({
    nl: {
      ...nl,
      messages: {
        ...nl.messages,
        accepted: '{field} moet worden geaccepteerd.',
        bic: '{field} moet een geldige BIC zijn in het formaat BANKNL2A.',
        iban: '{field} moet een geldig IBAN zijn in het formaat NL12BANK0123456789.',
        phone_number: '{field} moet een geldig telefoonnummer zijn.',
        postal_code: '{field} moet een geldige postcode zijn in het formaat 1234AB.',
        vat_number: '{field} moet een geldig BTW-nummer zijn in het formaat NL123456789B01.',
      },
    },
  }),
});

setLocale('nl');
