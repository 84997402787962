export const errorNotFoundRoute = 'error-not-found';
export const errorUnexpected = 'error-unexpected';

export default [
  {
    path: '/error/not-found',
    name: errorNotFoundRoute,
    component: () => import('../views/error/NotFoundPage.vue'),
    meta: {
      layout: 'error',
    },
  },
  {
    path: '/error/unexpected',
    name: errorUnexpected,
    component: () => import('../views/error/UnexpectedPage.vue'),
    meta: {
      layout: 'error',
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'error',
    component: () => import('../views/error/NotFoundPage.vue'),
    meta: {
      layout: 'error',
    },
  },
];
