import { RouteRecordRaw } from 'vue-router';

export const deviceQrOrderRoute = 'deviceQr.show';

export const deviceQrRoutes: Readonly<RouteRecordRaw[]> = [
  {
    path: '/device-qr/:code',
    name: deviceQrOrderRoute,
    component: () => import('../views/device-qr/DeviceQrOrderPage.vue'),
  },
];
