import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import { nl } from 'vuetify/locale';
import config from '../configs';

/**
 * Vuetify Plugin
 * Main components library
 *
 * https://vuetifyjs.com/
 *
 */
export default createVuetify({
  defaults: {
    VCard: {
      variant: 'flat',
    },
  },
  icons: {
    defaultSet: 'mdi',
  },
  locale: {
    locale: 'nl',
    messages: { nl },
  },
  theme: {
    defaultTheme: config.theme.globalTheme,
    themes: {
      dark: config.theme.dark,
      light: config.theme.light,
    },
  },
});
